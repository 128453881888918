import { FC, useEffect } from 'react';
import { useTelegram } from '../../core/hooks';

/**
 * The props type of {@link MainButton | `MainButton`}.
 */
export interface MainButtonProps {
    /** The back button press event handler */
    onClick: () => void;
    text: string;
    color?: string;
    isShow?: boolean;
    isDisabled?: boolean;
}

let WebAppMainButton: any = window.Telegram.WebApp.MainButton;

const TelegramMainButton: FC<MainButtonProps> = ({ onClick, text, color, isShow = true, isDisabled = false }) => {

    const {
        onDisableMainButton,
        onEnableMainButton
    } = useTelegram();

    useEffect(() => {
        WebAppMainButton.show();
        return () => {
            WebAppMainButton.hide();
        };
    }, []);

    useEffect(() => {
        WebAppMainButton.setText(text);
    }, [text])

    useEffect(() => {
        WebAppMainButton.color = color;
    }, [color])

    useEffect(() => {
        isShow ? WebAppMainButton.show() : WebAppMainButton.hide();
    }, [isShow])

    useEffect(() => {
        isDisabled ? onDisableMainButton() : onEnableMainButton();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisabled])

    useEffect(() => {
        if (!onClick) {
            return;
        }

        WebAppMainButton.onClick(onClick);
        return () => {
            WebAppMainButton.offClick(onClick);
        };
    }, [onClick]);

    return null;
};

export default TelegramMainButton;