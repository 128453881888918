import cn from "classnames";
import s from "./checkout.module.css";
import { IStatusAlert } from "../../core/types";
import { useTelegram } from "../../core/hooks/useTelegram";
import { TelegramBackButton, TelegramMainButton } from "../../components";
import { Header } from "../../components/Header";
import { ICheckoutInfo } from "../../core/types/ICheckoutInfo";
import { API_PaymentYooKassa } from "../../core/services/post";
import { EAlertStatus, EAlertText } from "../../core/enums";
import { urlFillmartWebApp } from "../../core/consts";



interface ICheckoutProps {
    checkoutInfo: ICheckoutInfo | undefined;
    setPage: any;
    identityToken: string;
    setStatusAlert: (value: IStatusAlert) => void;
}

/**
* Страница сформированного заказа перед оплатой
*/

const Checkout: React.FC<ICheckoutProps> = (
    {
        checkoutInfo,
        setPage,
        identityToken,
        setStatusAlert
    }
) => {

    const {
        tg,
        feedback,
        backButton,
        mainButton,
        toogleProgressTelegram
    } = useTelegram();

    const mainButtonText: string = "ОПЛАТИТЬ  " + checkoutInfo?.totalOrder + " ₽";

    // #region Functions
    const onClickMainButtonHandler = () => {
        tg.requestWriteAccess(async (writeAccess: boolean) => {
            if (checkoutInfo && writeAccess) {
                toogleProgressTelegram(true);
                const { orderGuid } = checkoutInfo;
                try {
                    const returnUrl: string = `${urlFillmartWebApp}?startapp=${orderGuid}`;

                    const response: any = await API_PaymentYooKassa(orderGuid, returnUrl);
                    // tg://resolve?domain=<dev_fillmart_bot>&appname=<fillmart>&startapp=<start_parameter>
                    // tg://resolve?domain=dev_fillmart_bot&appname=fillmart
                    const { Entity, IsSuccess, Message } = response;

                    if (IsSuccess) {
                        feedback.notificationOccurred("success");
                        tg.openLink(Entity?.ConfirmationUrl, true)
                    } else {
                        setStatusAlert({ text: Message ? Message : EAlertText.paymentError, status: EAlertStatus.error });
                    }
                } catch (error) {
                    console.log(error);
                    setStatusAlert({ text: EAlertText.paymentError, status: EAlertStatus.error });
                }
                toogleProgressTelegram(false);
            }
        })
    };

    const onClickBack = () => {
        mainButton.offClick(onClickMainButtonHandler);

        backButton.hide();

        setPage(2);
        feedback.selectionChanged();
        backButton.offClick(onClickBack);
    }
    // #endregion

    return (
        <div className={cn(s.checkout)}>

            <Header
                titleName={`Заказ #${checkoutInfo?.orderNumber}`}
            />

            <TelegramMainButton
                onClick={() => onClickMainButtonHandler()}
                text={mainButtonText}
            />

            <TelegramBackButton onClick={() => onClickBack()} />
        </div>
    )
}

export default Checkout;