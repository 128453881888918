import { useEffect, useState } from "react";
import "./app.css";
import { API_CheckOrCreateUser, API_GetProducts, API_GetPromotionImageByName } from "./core/services/get";
import { Cart, Catalog, Checkout, Stand } from "./pages";
import { IProduct, IPromotion, IStatusAlert } from "./core/types";
import { isGuid, sorting } from "./core/utils";
import { AlertStatus, Loader } from "./components";
import { EAlertStatus, EAlertText } from "./core/enums";
import { useCart, useTelegram } from "./core/hooks";
import { ICheckoutInfo } from "./core/types/ICheckoutInfo";
import { PayInfo } from "./pages/payInfo";

const App = () => {
  // #region States
  const [showPage, setPage] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [identityToken, setIdentityToken] = useState("");
  const [statusAlert, setStatusAlert] = useState<IStatusAlert>({ text: "", status: "" });

  const {
    mainButton,
    colorScheme,
    feedback,
    telegramUserId,
    telegramUserName,
    telegramStartParam,
    onExpand,
    onReady,
    onEventThemeChanged,
    onDisableClosingConfirmation,
    onEnableClosingConfirmation
  } = useTelegram();

  const backgroundColor: string = colorScheme === "dark" ? "dark" : "light";

  const [colorTheme, setColorTheme] = useState<string>(colorScheme);

  // const [showComment, setComment] = useState("");
  const [selectPromotion, setSelectPromotion] = useState<IPromotion>({ Name: "", FullName: "", ImageUrl: null, SharingText: null });
  const { cart, setCart, total, increase, decrease } = useCart();
  const [checkoutInfo, setCheckoutInfo] = useState<ICheckoutInfo>();

  const formationProducts = async (promotionName: string, token: string) => {
    setLoading(true);
    await API_GetProducts(token, promotionName).then((response: IProduct) => {
      const { Entity, IsSuccess, Message } = response;
      try {
        if (IsSuccess) {
          setCart(Entity.map((product) => ({
            "id": product.Id,
            "name": product.Name,
            "fullname": product.FullName,
            "description": product.Description,
            "sketch": product.Sketch,
            "price": product.Price,
            "img": product.Image[0] ? product.Image[0] : "images/default-image.png",
            "count": 0,
            "priceTotal": 0,
            "quantity": product.Quantity,
            "onlyForDoctor": product.OnlyForDoctor
          })).sort(sorting));

          setPage(1);
          feedback.notificationOccurred("success");

        } else {
          setStatusAlert({ text: Message ? Message : EAlertText.product, status: EAlertStatus.error });
        }

      } catch (error) {
        setStatusAlert({ text: EAlertText.product, status: EAlertStatus.error });
      }
    })
  }

  /** Начало работы WebApp*/
  const startWeb = async () => {
    window.localStorage.removeItem("orderGuid")
    onExpand();
    let token: string = "";
    let promotionName: string = "";

    await API_CheckOrCreateUser(telegramUserId, telegramUserName).then((response: any) => {
      const { Entity, IsSuccess, Message } = response;

      try {
        if (IsSuccess) {
          token = Entity;
          setIdentityToken(token);
        } else {
          setStatusAlert({ text: Message ? Message : EAlertText.user, status: EAlertStatus.error });
        }
      } catch (error) {
        setStatusAlert({ text: EAlertText.user, status: EAlertStatus.error });
      }
    })
    //TODO: Нужно вынести в отдельный обработчик работу со стартовыми параметрами приложения
    if (isGuid(telegramStartParam)) {
      setPage(4);
    } else {
      promotionName = telegramStartParam;
      if (token && promotionName !== undefined) {
        await API_GetPromotionImageByName(token, promotionName.toLowerCase()).then((response: any) => {
          const { Entity, IsSuccess, Message } = response;
          try {
            if (IsSuccess) {
              setSelectPromotion(Entity)
            } else {
              setPage(0);
              setStatusAlert({ text: Message ? Message : EAlertText.promotion, status: EAlertStatus.error });
            }

          } catch (error) {
            setPage(0);
            setStatusAlert({ text: EAlertText.promotion, status: EAlertStatus.error });
          }
        })
        formationProducts(promotionName.toLowerCase(), token);
      }
      else if (promotionName === undefined) {
        setPage(0);
      }
    }


    onReady();
  };

  useEffect(() => {

    mainButton.color = "#23C16B";
    onEventThemeChanged(setColorTheme)

    startWeb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    total.count > 0 ? onEnableClosingConfirmation() : onDisableClosingConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total])


  return (
    <div className={['App', backgroundColor].join(' ')}>
      {loading && <Loader height={15} />}

      {showPage === 0 && identityToken &&
        <Catalog
          identityToken={identityToken}
          setStatusAlert={setStatusAlert}
          formationProducts={formationProducts}
          loading={loading}
          setLoading={setLoading}
          setSelectPromotion={setSelectPromotion}
        />
      }

      {showPage === 1 &&
        <Stand
          cart={cart}
          increase={increase}
          decrease={decrease}
          total={total}
          setPage={setPage}
          setStatusAlert={setStatusAlert}
          colorTheme={colorTheme}
          loading={loading}
          setLoading={setLoading}
          selectPromotion={selectPromotion}
        />
      }

      {showPage === 2 &&
        <Cart
          setLoading={setLoading}
          total={total}
          cart={cart}
          setPage={setPage}
          identityToken={identityToken}
          setStatusAlert={setStatusAlert}
          colorTheme={colorTheme}
          setCheckoutInfo={setCheckoutInfo}
        />
      }

      {showPage === 3 &&
        <Checkout
          checkoutInfo={checkoutInfo}
          setPage={setPage}
          identityToken={identityToken}
          setStatusAlert={setStatusAlert}
        />
      }

      {showPage === 4 &&
        <PayInfo
          setStatusAlert={setStatusAlert}
          setLoading={setLoading}
        />
      }

      <AlertStatus
        statusAlert={statusAlert}
        setStatusAlert={setStatusAlert}
      />

    </div>
  );
}

export default App;
