import axios from "axios";
import { fillmartApiURL, fillmartAuthBasic } from "../config";

export const API_GetProducts = async (identityToken: string, promotionName: string) => {
    try {
        const response: any = await axios.request({
            url: 'GetProduct',
            baseURL: fillmartApiURL,
            method: 'get',
            params: {
                promotionName: promotionName
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "identityToken": identityToken,
                "Authorization": fillmartAuthBasic
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const API_CheckOrCreateUser = async (telegramUserId: number, telegramUserName: string) => {
    try {


        const response: any = await axios.request({
            url: 'CheckOrCreateUser',
            baseURL: fillmartApiURL,
            method: 'get',
            timeout: 0,
            params: {
                telegramUserId: telegramUserId,
                telegramUserName: telegramUserName
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": fillmartAuthBasic
            },
        });
         return response.data;
    } catch (error) {
        console.log(error);
    }

}

export const API_GetPromotionList = async (identityToken: string) => {
    try {
        const response: any = await axios.request({
            url: 'GetPromotionList',
            baseURL: fillmartApiURL,
            method: 'get',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "identityToken": identityToken,
                "Authorization": fillmartAuthBasic
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const API_GetPromotionImageByName = async (identityToken: string, promotionName: string) => {
    try {
        const response: any = await axios.request({
            url: 'GetPromotionImageByName',
            baseURL: fillmartApiURL,
            method: 'get',
            params: {
                promotionName: promotionName
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "identityToken": identityToken,
                "Authorization": fillmartAuthBasic,
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const API_GetCustomerAddresses = async (identityToken: string, telegramUserId: number) => {
    try {
        const response: any = await axios.request({
            url: 'GetCustomerAddresses',
            baseURL: fillmartApiURL,
            method: 'get',
            params: {
                telegramUserId: telegramUserId
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "identityToken": identityToken,
                "Authorization": fillmartAuthBasic
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const API_GetCustomerInfo = async (identityToken: string, telegramUserId: number) => {
    try {
        const response: any = await axios.request({
            url: 'GetCustomerInfo',
            baseURL: fillmartApiURL,
            method: 'get',
            params: {
                telegramUserId: telegramUserId
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "identityToken": identityToken,
                "Authorization": fillmartAuthBasic
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const API_GetPayInfo = async (orderGuid: string) => {
    try {
        const response: any = await axios.request({
            url: `PayInfo/${orderGuid}`,
            baseURL: fillmartApiURL,
            method: 'get',
            // params: {
            //     orderGuid: orderGuid
            // },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": fillmartAuthBasic
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}