//TODO: Дописать в конце Error у ошибок
export enum EAlertText {
    product = "Ошибка получения товаров",
    promotion = "Ошибка получения акций",
    user = "Ошибка обновления пользовательских данных",
    paymentError = "Ошибка открытия платежа",
    orderCreateError = "Ошибка создания заказа",
    addresses = "Ошибка получения адресов",
    updAddress = "Ошибка обновления адреса",
    delAddress = "Ошибка удаления адреса",
    selectedAddressError = "Ошибка выбора адреса",
    shippingMethodsError = "Ошибка получения методов доставки",
    customerInfoError = "Ошибка обновления пользовательских данных",
    payInfoError = "Ошибка статуса платежа"
}