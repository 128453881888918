import { FC, useState, memo } from "react";
import { ICardActive } from "../../core/types/ICardActive";

import "./stand.css"
import Product from "./Product/Product";
import { Card } from "../card";
import { ICart, IPromotion, IStatusAlert, ITotal } from "../../core/types";
import { useStatusLoadingImg } from "../../core/hooks/useStatusLoadingImg";
import { Promotion, TelegramBackButton, TelegramMainButton } from "../../components";
import { useTelegram } from "../../core/hooks/useTelegram";

interface IStandProps {
    cart: ICart[];
    increase: (id: number) => void;
    decrease: (id: number) => void;
    total: ITotal;
    setPage: any;
    setStatusAlert: (value: IStatusAlert) => void;
    colorTheme: string;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    selectPromotion: IPromotion;
}
/**
* Витрина товаров
* @param cart передача товаров
*/
const Stand: FC<IStandProps> = ({
    cart,
    increase, decrease, total,
    setPage,
    setStatusAlert,
    colorTheme,
    loading, setLoading,
    selectPromotion
}) => {


    const mainButtonText: string = "ЗАКАЗАТЬ (" + total.price.toLocaleString() + " ₽)";

    const { onLoadImg } = useStatusLoadingImg(cart.filter((product) => product.quantity > 0).length + 1, setLoading);

    const { feedback, backButton, mainButton } = useTelegram();

    const [cardActive, setCardActive] = useState<ICardActive>({
        "selected": false,
        "productID": 0
    });

    // #region Functions
    const onClickMainButtonHandler = () => {
        feedback.impactOccurred('light');
        setPage(2);
        mainButton.hide();
        mainButton.offClick(onClickMainButtonHandler)
    };

    const onProduct = (id: number) => {
        feedback.impactOccurred('medium');
        setCardActive({
            "selected": !cardActive.selected,
            "productID": id
        })
    };
    // #endregion

    const onClickBack = () => {
        backButton.hide();
        mainButton.offClick(onClickMainButtonHandler);
        setPage(0);
        feedback.selectionChanged();
        backButton.offClick(onClickBack);
    }

    return (
        <div className={'stand'}>
            <Promotion promotion={selectPromotion} onLoadImg={onLoadImg} viewStand />
            {cart.length > 0 && <div className="stand-area">
                {cart.filter((product: ICart) => product.quantity !== 0).map((product: ICart, key: number) =>
                    <Product
                        product={product} key={key} onProduct={onProduct}
                        setStatusAlert={setStatusAlert}
                        increase={increase} decrease={decrease} onLoadImg={onLoadImg}
                        colorTheme={colorTheme} noLine={key === 0}
                    />
                )}
            </div>}

            {cardActive.selected &&
                <Card
                    cart={cart} colorTheme={colorTheme}
                    productID={cardActive.productID} setCardActive={setCardActive}
                    increase={increase} decrease={decrease}
                    mainButtonText={mainButtonText} totalPrice={total.price}
                    setStatusAlert={setStatusAlert}
                />
            }
            {total.count > 0 && !loading &&
                <TelegramMainButton
                    onClick={() => onClickMainButtonHandler()}
                    text={mainButtonText}
                />
            }
            {!cardActive.selected && !loading &&
                <TelegramBackButton
                    onClick={() => onClickBack()}
                />}
        </div>
    )
}

export default memo(Stand);