import { AddButton } from "../../../../components";
import { ICart, IStatusAlert } from "../../../../core/types";
import "./addToCartExtendedStand.css"

interface FormLineType {
    productID: number;
    increase: (id: number) => void;
    decrease: (id: number) => void;
    product: ICart;
    setStatusAlert?: (value: IStatusAlert) => void;
}

/**
* Компонент добавления товара в корзину, с ценной и подсчётом общей суммы и колличества набранного товара. 
* @component
* @param productID id товара
* @param increase Функция добавления товара в корзину
* @param decrease Функция вычитания товара из корзины
* @param product Данные о позиции товара
* @param setStatusAlert Вызов алерта
*/
const AddToCartExtendedStand: React.FC<FormLineType> = ({ productID, increase, decrease, product, setStatusAlert }) => {

    const { price, count, quantity } = product;

    return (
        <div className="addToCartExtendedStand">
            <AddButton productID={productID}
                count={count} quantity={quantity}
                increase={increase} decrease={decrease}
                name={price.toLocaleString() + " ₽"} setStatusAlert={setStatusAlert}
                btn_style={{ "height": 32 }}
                // addBtn_style={{ "borderRadius": 12, "fontSize": 15, }}
                // btnMinusPlus_style={{ "backgroundColor": "#CBD5E0", "borderRadius": 12 }}
            />

            <div>
                {count !== 0 && <span>
                    <span className="addToCartExtendedStand_price">{price.toLocaleString()} ₽</span>
                    <span className="addToCartExtendedStand_amount"><span style={{ color: "#9990FF" }}>x </span>{count} шт.</span>
                    <span className="addToCartExtendedStand_total_price">{(price * count).toLocaleString()} ₽</span>
                </span>}
            </div>
        </div>
    )
}


export default AddToCartExtendedStand;