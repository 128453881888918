import { useEffect, useState } from "react";
import "./addButton.css"
import { IStatusAlert } from "../../core/types";
import { EAlertStatus } from "../../core/enums";

interface IAddButtonProps {
    increase: (id: number) => void;
    decrease: (id: number) => void;
    productID: number;
    count: number;
    quantity: number;
    name: string | number;

    btn_style?: any;
    addBtn_style?: any;
    btnMinusPlus_style?: any;
    setStatusAlert?: (value: IStatusAlert) => void;
}

const AddButton: React.FC<IAddButtonProps> = ({
    productID, count, quantity, increase, decrease, name,
    btn_style, btnMinusPlus_style, addBtn_style, setStatusAlert
}) => {

    const [showClickButton, setClickButton] = useState(false);

    const disabledButton: string = count >= quantity ? 'button-disabled' : '';

    const onClickAddHandler = () => {
        setClickButton(true);
        increase(productID)
    }

    useEffect(() => {
        if (disabledButton && setStatusAlert)
            setStatusAlert({ text: "Осталось " + quantity + " шт.", status: EAlertStatus.warning });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabledButton])


    return (
        <div className="addButton" style={btn_style}>

            {count > 0 && <div className="addButton-minus" style={btnMinusPlus_style} onClick={() => decrease(productID)}>
                <span className="minus" />
            </div>}
            {count > 0 && <div className={['addButton-plus', disabledButton].join(' ')} style={btnMinusPlus_style} onClick={() => !disabledButton && increase(productID)}>
                <span className="minus"><span className="plus" /></span>
            </div>}

            {count === 0 && <div className={`addButton-add ${showClickButton && "animationslidein"}`} style={addBtn_style} onClick={onClickAddHandler}>
                {name}
            </div>}
        </div>
    )
}

export default AddButton;