import cn from "classnames";
import s from "./customerInfo.module.css";
import { useEffect, useState } from "react";
import { useTelegram } from "../../core/hooks";
import { Input, TelegramBackButton, TelegramMainButton } from "../../components";
import { Header } from "../../components/Header";
import { ICustomerInfo, IStatusAlert } from "../../core/types";
import { API_SetCustomerInfo } from "../../core/services/post";
import { EAlertStatus, EAlertText } from "../../core/enums";

interface ICustomerInfoProps {
    onBack: () => void;
    customerInfo?: ICustomerInfo;
    identityToken: string;
    setStatusAlert: (value: IStatusAlert) => void;
    refreshCustomerInfo: () => void;
}

/**
* Страница изменения контактной информации клиента
*/
const CustomerInfo: React.FC<ICustomerInfoProps> = (
    {
        onBack,
        customerInfo,
        identityToken,
        setStatusAlert,
        refreshCustomerInfo
    }
) => {

    const [fullName, setFullName] = useState(`${customerInfo?.FirstName} ${customerInfo?.LastName}`);
    const [email, setEmail] = useState(customerInfo?.Email);
    const [phoneNumber, setPhoneNumber] = useState(customerInfo?.PhoneNumber);

    const isDisableMainButton =
        `${customerInfo?.FirstName} ${customerInfo?.LastName}` !== fullName
        || customerInfo?.Email !== email
        || customerInfo?.PhoneNumber !== phoneNumber;

    const handleFullNameChange = (fullName: string) => {
        setFullName(fullName);
    };

    const handleEmailChange = (email: string) => {
        setEmail(email);
    };

    const handlePhoneNumberChange = (phoneNumber: string) => {
        setPhoneNumber(phoneNumber);
    };

    const { onExpand, telegramUserId, feedback } = useTelegram();

    const mainButtonText = "Сохранить персональные данные";

    const onClickMainButtonHandler = async () => {

        const nameParts = fullName.split(' ');

        const customerInfoUpdate: ICustomerInfo = {
            TelegramUserId: telegramUserId,
            Email: email ?? "",
            FirstName: nameParts[0] ?? "",
            LastName: nameParts[1] ?? "",
            PhoneNumber: phoneNumber ?? ""
        };

        try {
            const response: any = await API_SetCustomerInfo(identityToken, customerInfoUpdate);
            const { IsSuccess, Message } = response;

            if (IsSuccess) {
                feedback.notificationOccurred("success");
                refreshCustomerInfo();
                onBack();
            } else {
                setStatusAlert({ text: Message ? Message : EAlertText.customerInfoError, status: EAlertStatus.error });
            }
        } catch (error) {
            console.log(error);
            setStatusAlert({ text: EAlertText.customerInfoError, status: EAlertStatus.error });
        }
        onBack();
        // }
    };

    useEffect(() => {
        onExpand();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={cn(s.customerInfo)}>
            <Header
                titleName="Персональные данные"
            />

            <div className={cn(s.fields)}>
                <div className={cn(s.fields_group)}>
                    <Input
                        id="input-firstName"
                        placeholder="Имя Фамилия"
                        value={fullName}
                        onChange={(e) => handleFullNameChange(e.target.value)}
                    />
                    <Input
                        id="input-phoneNumber"
                        placeholder="Номер телефона"
                        value={phoneNumber}
                        onChange={(e) => handlePhoneNumberChange(e.target.value)}
                    />
                    <Input
                        id="input-lastName"
                        placeholder="Электронная почта"
                        value={email}
                        onChange={(e) => handleEmailChange(e.target.value)}
                    />
                </div>
            </div>

            <TelegramBackButton onClick={() => onBack()} />
            <TelegramMainButton onClick={() => onClickMainButtonHandler()} text={mainButtonText} isDisabled={!isDisableMainButton}
            />
        </div>
    )
}

export default CustomerInfo;