import cn from "classnames";
import s from "./shippingAddresses.module.css";
import { useEffect, useState } from "react";
import { useTelegram } from "../../core/hooks";
import { ActionView, TelegramBackButton, TelegramMainButton } from "../../components";
import { IShippingAddress } from "../../core/types/IShippingAddress";
import { AddOrEditShippingAddress } from "./addOrEditShippingAddress";
import { IStatusAlert } from "../../core/types";
import { API_SetSelectedCustomerAddress } from "../../core/services/post";
import { EAlertStatus, EAlertText, ETypeButton } from "../../core/enums";
import { Header } from "../../components/Header";

interface IShippingAddressesProps {
    onBack: () => void;
    shippingAddresses: IShippingAddress[];
    identityToken: string;
    setStatusAlert: (value: IStatusAlert) => void;
    refreshShippingAddresses: () => void;
}

/**
* Страница выбора адреса доставки
*/
const ShippingAddresses: React.FC<IShippingAddressesProps> = (
    {
        shippingAddresses,
        onBack,
        identityToken,
        setStatusAlert,
        refreshShippingAddresses
    }
) => {

    const [selectAddressId, setSelectAddressId] = useState<number>();

    const [isAddOrEditShippingAddressPage, setIsAddOrEditShippingAddressPage] = useState<{ view: boolean, shippingAddress: IShippingAddress | null }>({ view: false, shippingAddress: null });

    const { onExpand, telegramUserId, feedback } = useTelegram();

    const mainButtonText = "Выбрать адрес доставки";

    const onClickMainButtonHandler = async () => {

        if (selectAddressId)
            try {
                const response: any = await API_SetSelectedCustomerAddress(identityToken, telegramUserId, selectAddressId);
                const { IsSuccess, Message } = response;

                if (IsSuccess) {
                    feedback.notificationOccurred("success");
                    refreshShippingAddresses();
                    onBack();
                } else {
                    setStatusAlert({ text: Message ? Message : EAlertText.selectedAddressError, status: EAlertStatus.error });
                }
            } catch (error) {
                console.log(error);
                setStatusAlert({ text: EAlertText.selectedAddressError, status: EAlertStatus.error });
            }
    };

    const onEditShippingAddress = (address: IShippingAddress) => {
        setSelectAddressId(address.AddressId);
        setIsAddOrEditShippingAddressPage({ view: true, shippingAddress: address })
    };

    const onAddShippingAddress = () => {
        setIsAddOrEditShippingAddressPage({ view: true, shippingAddress: null })
    };

    useEffect(() => {
        onExpand();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const titleActionView = (address: IShippingAddress) => {
        return `${address.City}, ${address.Address1}, ${address.House}`
    }

    return (
        <div className={cn(s.shippingAddresses)}>
            <div className={cn(s.container, { [s.displayNone]: isAddOrEditShippingAddressPage.view })}>
                <Header
                    titleName="Ваши адреса доставки"
                    textButton="Добавить адрес"
                    typeButton={ETypeButton.add}
                    onClickButton={onAddShippingAddress}
                />
                <div className={cn(s.list)}>
                    {shippingAddresses.map((address: IShippingAddress) =>
                        <ActionView
                            title={titleActionView(address)}
                            onClickView={() => setSelectAddressId(address.AddressId)}
                            onClickAction={() => onEditShippingAddress(address)}
                            select={selectAddressId === address.AddressId}
                        />
                    )}
                </div>
            </div>

            {isAddOrEditShippingAddressPage.view &&
                <AddOrEditShippingAddress
                    identityToken={identityToken} setStatusAlert={setStatusAlert}
                    onBack={() => setIsAddOrEditShippingAddressPage({ view: false, shippingAddress: null })}
                    shippingAddress={isAddOrEditShippingAddressPage.shippingAddress}
                    refreshShippingAddresses={() => refreshShippingAddresses()}
                />
            }

            {!isAddOrEditShippingAddressPage.view &&
                <>
                    < TelegramBackButton
                        onClick={() => onBack()}
                    />
                    <TelegramMainButton
                        onClick={() => onClickMainButtonHandler()}
                        text={mainButtonText}
                        isDisabled={!selectAddressId}
                    />
                </>
            }
        </div>
    )
}

export default ShippingAddresses;