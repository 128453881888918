import { useState } from "react";
import { IShippingAddress } from "../types/IShippingAddress";

const useShippingAddressFields = ( initialValues: IShippingAddress) => {
    const [fieldsShippingAddress, setFieldsShippingAddress] = useState<IShippingAddress>(initialValues);


    const handleFieldChange = (fieldName: keyof IShippingAddress, value: string) => {
        setFieldsShippingAddress((prevFields) => ({
            ...prevFields,
            [fieldName]: value,
        }));
    };

    return {
        fieldsShippingAddress,
        handleFieldChange
    };
};

export default useShippingAddressFields;