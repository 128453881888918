import cn from "classnames";
import s from "./shippingMethods.module.css";
import { useEffect, useState } from "react";
import { useTelegram } from "../../core/hooks";
import { DeliveryMethodView, TelegramBackButton, TelegramMainButton } from "../../components";
import { Header } from "../../components/Header";
import { ITarriff } from "../../core/types";

interface IShippingMethodsProps {
    setShippingMethod: (method: ITarriff) => void;
    onBack: () => void;
    shippingMethods: ITarriff[];
}

/**
* Страница выбора адреса доставки
*/
const ShippingMethods: React.FC<IShippingMethodsProps> = (
    {
        setShippingMethod,
        onBack,
        shippingMethods
    }
) => {

    const [selectMethod, setSelectMethod] = useState<ITarriff | null>(null);

    const { onExpand } = useTelegram();

    const mainButtonText = "Изменить метод доставки";

    const onClickMainButtonHandler = () => {
        if (selectMethod) {
            setShippingMethod(selectMethod);
            onBack();
        }
    };
    console.log(selectMethod, shippingMethods[0])
    useEffect(() => {
        onExpand();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={cn(s.shippingMethods)}>
            <div className={cn(s.container)}>
                <Header titleName="Выберите способ доставки" />
                <div className={cn(s.list)}>
                    {shippingMethods.map((method: ITarriff) =>
                        <DeliveryMethodView
                            name={method.Name}
                            description={method.Description}
                            rate={method.RateString}
                            onClickView={() => setSelectMethod(method)}
                            select={selectMethod?.ShippingMethodId === method.ShippingMethodId}
                        />
                    )}
                </div>
            </div>

            <TelegramBackButton onClick={() => onBack()} />
            <TelegramMainButton onClick={() => onClickMainButtonHandler()} text={mainButtonText} isDisabled={!selectMethod} />
        </div>
    )
}

export default ShippingMethods;