import { FC, useEffect } from 'react';

/**
 * The props type of {@link BackButton | `BackButton`}.
 */
export interface BackButtonProps {
    /** The back button press event handler */
      onClick: () => void;
}

let WebAppBackButton: any = window.Telegram.WebApp.BackButton;

const TelegramBackButton: FC<BackButtonProps> = ({ onClick }) => {

    useEffect(() => {
        WebAppBackButton.show();
        return () => {
            WebAppBackButton.hide();
        };
    }, []);

    useEffect(() => {
        if (!onClick) {
            return;
        }

        WebAppBackButton.onClick(onClick);
        return () => {
            WebAppBackButton.offClick(onClick);
        };
    }, [onClick]);

    return null;
};

export default TelegramBackButton;