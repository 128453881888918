import { useEffect, useState } from "react";
import { IStatusLoadingImg } from "../types";

const useStatusLoadingImg = (length: number, setLoading: (loading: boolean) => void) => {
    const [statusLoadingImg, setStatusLoadingImg] = useState<IStatusLoadingImg>({ count: 0, loaded: false });

    const onLoadImg = () => {
        setStatusLoadingImg((state) => ({ ...state, count: state.count + 1 }));
    };

    useEffect(() => {
        if (!statusLoadingImg.loaded && length > 0 && statusLoadingImg.count === length) {
            setStatusLoadingImg({ ...statusLoadingImg, loaded: true });
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onLoadImg])

    return { onLoadImg }
}
export { useStatusLoadingImg };
