
const useSVG = () => {

    const shareIcon = <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.7">
            <rect width="46" height="46" rx="23" fill="#E1E8FF" />
            <path d="M17 23V29C17 29.3978 17.158 29.7794 17.4393 30.0607C17.7206 30.342 18.1022 30.5 18.5 30.5H27.5C27.8978 30.5 28.2794 30.342 28.5607 30.0607C28.842 29.7794 29 29.3978 29 29V23" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26 18.5L23 15.5L20 18.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23 15.5V25.25" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
    </svg>

    const arrowRightIcon = <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6L0.292893 10.2929Z" fill="var(--tg-theme-text-color)" fill-opacity="0.5" />
    </svg>


    return {
        shareIcon, arrowRightIcon
    }
}

export default useSVG;