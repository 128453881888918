import cn from "classnames";
import s from "./input.module.css";
import { useState } from "react";
import { Select } from "../Select";

interface IInputProps {
    value?: string | number | null;
    onChange?: (event: any) => void;
    onClick?: () => void;
    id?: string;
    placeholder?: string;
    readOnly?: boolean;
    suggestions?: string[] | number[];
    selected?: (select: string | number | null) => void;
    valid?: boolean;
    autoComplete?: "off" | "on";
}

const Input: React.FC<IInputProps> = (
    {
        value,
        onClick,
        onChange,
        id,
        placeholder,
        readOnly,
        suggestions,
        selected,
        valid,
        autoComplete = "off"
    }
) => {
    const [isSelectOpen, setSelectOpen] = useState(false);

    const handleFocus = () => {
        setSelectOpen(true);
    };

    return (
        <div className={cn(s.container)}
            onFocus={handleFocus}
        >
            <input
                className={cn(s.input, { [s.valid]: valid }, { [s.noValid]: valid === false })}
                value={value ?? ""}
                onChange={onChange}
                onClick={onClick}
                id={id}
                readOnly={readOnly}
                placeholder={placeholder}
                autoComplete={autoComplete}
            />
            {suggestions && selected &&
                < Select options={suggestions}
                    onClick={(select) => selected(select)}
                    onClose={() => setSelectOpen(false)}
                    isOpen={isSelectOpen}
                    selected={""}
                />
            }
        </div>
    )
}

export default Input;