import { useEffect, useState, memo } from "react";
import { API_CreateOrUpdateOrder, API_GetShippingMethods } from "../../core/services/post";
import cn from "classnames";
import s from "./cart.module.css";
import ItemCart from "./ItemCart/ItemCart";
import { ICart, ICustomerInfo, IOrder, IStatusAlert, ITarriff, ITotal } from "../../core/types";
import { IOrderResponse } from "../../core/models";
import { EAlertStatus, EAlertText, ETypeButton } from "../../core/enums";
import { useTelegram } from "../../core/hooks/useTelegram";
import { ShippingAddresses } from "../shippingAddresses";
import { Input, TelegramBackButton, TelegramMainButton } from "../../components";
import { ShippingMethods } from "../shippingMethods";
import { ICustomerAddressesResponse } from "../../core/models/ICustomerAddresses.model";
import { API_GetCustomerAddresses, API_GetCustomerInfo } from "../../core/services/get";
import { IShippingAddress } from "../../core/types/IShippingAddress";
import { IShippingMethodsResponse } from "../../core/models/IShippingMethodsResponse.model";
import { Header } from "../../components/Header";
import { ICustomerInfoResponse } from "../../core/models/ICustomerInfo.model";
import { CustomerInfo } from "../customerInfo";
import { ICheckoutInfo } from "../../core/types/ICheckoutInfo";


interface ICartProps {
    setLoading: (loading: boolean) => void;
    total: ITotal;
    cart: ICart[];
    setPage: any;
    identityToken: string;
    setStatusAlert: (value: IStatusAlert) => void;
    colorTheme: string;
    setCheckoutInfo: (value: ICheckoutInfo) => void;
}

/**
* Список набранных товаров пользователя в корзину
* @param cart Корзина пользователя
*/
const Cart: React.FC<ICartProps> = (
    {
        setLoading,
        total,
        cart,
        setPage,
        identityToken,
        setStatusAlert,
        setCheckoutInfo
    }
) => {

    const MinApiVersion = '6.7';

    const {
        tg,
        feedback,
        backButton,
        mainButton,
        telegramUserId,
        initDataUnsafe,
        isVersionAtLeast,
        onEnableMainButton,
        toogleProgressTelegram
    } = useTelegram();

    const order = cart.filter((product) => product.count > 0);
    const hasOnlyForDoctor = order.some(item => item.onlyForDoctor);

    const [isShippingAddressesPage, setIsShippingAddressesPage] = useState(false);
    const [isShippingMethodsPage, setIsShippingMethodsPage] = useState(false);
    const [isCustomerInfoPage, setIsCustomerInfoPage] = useState(false);

    const [selectedShippingAddress, setSelectedShippingAddress] = useState<IShippingAddress>();
    const [selectedShippingMethod, setSelectedShippingMethod] = useState<ITarriff>();

    const [shippingAddresses, setShippingAddresses] = useState<IShippingAddress[]>([]);
    const [shippingMethods, setShippingMethods] = useState<ITarriff[]>([]);
    const [customerInfo, setCustomerInfo] = useState<ICustomerInfo>();

    const isShowWebAppButton: boolean = !isShippingAddressesPage && !isShippingMethodsPage && !isCustomerInfoPage;
    
    const viewTextShippingAddress = selectedShippingAddress ? `${selectedShippingAddress.City}, ${selectedShippingAddress.Address1}, ${selectedShippingAddress.House}` : "";
    const viewTextCustomerInfo =
        `${customerInfo?.FirstName ?? ""} ${customerInfo?.LastName ?? ""} ${customerInfo?.Email ? customerInfo.Email + `,` : ""} ${customerInfo?.PhoneNumber ? customerInfo.PhoneNumber + `,` : ""}`;
    const viewTextShippingMethod = `${selectedShippingMethod?.Name} ${selectedShippingMethod?.Description}, ${selectedShippingMethod?.RateString}`;

    const mainButtonText: string = "ОФОРМИТЬ  " + (total.price + (selectedShippingMethod?.Rate || 0)).toLocaleString() + " ₽";

    // #region Functions
    const listShippingMethodsAsync = async (addressId: number) => {

        const orderItems = order.map((product: any) => {
            return { ProductId: product.id, Amount: product.count, Price: product.price }
        });

        const infoShippingAddress = {
            AddressId: addressId, TelegramUserId: telegramUserId, OrderItems: orderItems
        };

        try {
            const response: IShippingMethodsResponse = await API_GetShippingMethods(identityToken, infoShippingAddress);
            const { Entity, IsSuccess, Message } = response;

            if (IsSuccess) {
                const tarriffs = Entity[0].Tarriffs;
                setShippingMethods(tarriffs || []);
                const selectedMethod = tarriffs.find(method => method.Selected);
                if (selectedMethod)
                    setSelectedShippingMethod(selectedMethod);
                feedback.notificationOccurred("success");
            } else {
                setStatusAlert({ text: Message ? Message : EAlertText.shippingMethodsError, status: EAlertStatus.error });
            }
        } catch (error) {
            console.log(error);
            setStatusAlert({ text: EAlertText.shippingMethodsError, status: EAlertStatus.error });
        }
    }

    const listShippingAddressesAsync = async () => {
        try {
            const response: ICustomerAddressesResponse = await API_GetCustomerAddresses(identityToken, telegramUserId);
            const { Entity, IsSuccess, Message } = response;

            if (IsSuccess) {
                setShippingAddresses(Entity || []);
                const selectedAddress = Entity.find(address => address.Selected);
                if (selectedAddress) {
                    setSelectedShippingAddress(selectedAddress);
                    listShippingMethodsAsync(selectedAddress.AddressId);
                }
                feedback.notificationOccurred("success");
            } else {
                setStatusAlert({ text: Message ? Message : EAlertText.addresses, status: EAlertStatus.error });
            }
        } catch (error) {
            console.log(error);
            setStatusAlert({ text: EAlertText.addresses, status: EAlertStatus.error });
        }
    }

    const customerInfoAsync = async () => {
        try {
            const response: ICustomerInfoResponse = await API_GetCustomerInfo(identityToken, telegramUserId);
            const { Entity, IsSuccess, Message } = response;

            if (IsSuccess) {
                setCustomerInfo(Entity);
                feedback.notificationOccurred("success");
            } else {
                setStatusAlert({ text: Message ? Message : EAlertText.addresses, status: EAlertStatus.error });
            }
        } catch (error) {
            console.log(error);
            setStatusAlert({ text: EAlertText.addresses, status: EAlertStatus.error });
        }
    }

    const createOrUpdateOrder = async (addressId: number, shippingMethodId: number) => {
        mainButton.offClick(onClickMainButtonHandler);
        const orderItems = order.map((product: any) => {
            return { id: product.id, label: product.name, price: product.price, count: product.count }
        });
        const invoiceSupported = isVersionAtLeast(MinApiVersion);
        // const orderGuid = window.localStorage.getItem("orderGuid")

        const params: IOrder = {
            order_data: orderItems,
            addressId: addressId,
            shippingMethodId: shippingMethodId,
            user_id: telegramUserId,
            user_hash: initDataUnsafe.hash,
            invoice: invoiceSupported ? 1 : 0,
            order_guid: null,
            comment: ""
        };
        toogleProgressTelegram(true);
        await API_CreateOrUpdateOrder(identityToken, JSON.stringify(params)).then((response: IOrderResponse) => {
            // window.localStorage.setItem("orderGuid", response.Entity.orderGuid);
            try {
                const { Entity, IsSuccess, Message } = response;

                if (IsSuccess && customerInfo && selectedShippingAddress && selectedShippingMethod) {
                    // toogleProgressTelegram(false);

                    const { OrderGuid, OrderNumber } = Entity;

                    setCheckoutInfo({
                        cart,
                        shippingAddress: selectedShippingAddress,
                        shippingMethod: selectedShippingMethod,
                        customerInfo,
                        orderGuid: OrderGuid,
                        orderNumber: OrderNumber,
                        totalOrder: (total.price + (selectedShippingMethod?.Rate || 0)).toLocaleString()
                    });

                    setPage(3);
                } else {
                    setStatusAlert({ text: Message ? Message : EAlertText.orderCreateError, status: EAlertStatus.error });
                }

            }
            catch (error) {
                // toogleProgressTelegram(false);
                setStatusAlert({ text: EAlertText.orderCreateError, status: EAlertStatus.error });
                mainButton.onClick(onClickMainButtonHandler);
            }
            toogleProgressTelegram(false);
        })
    }

    const onClickMainButtonHandler = () => {
        mainButton.enable();

        if (hasOnlyForDoctor)
            tg.showPopup({
                title: "Статус врача",
                message: "Подтверждаю наличие медицинского образования и принимаю ответственность за риски, связанные с последствиями применения препаратов при его отсутствии.",
                buttons: [
                    {
                        id: 1,
                        type: "default",
                        text: "Подтвердить"
                    },
                    {
                        id: 0,
                        type: "destructive",
                        text: "Отклонить"
                    }
                ]
            }, async (buttonID: any) => {
                if (buttonID === '1') {
                    if (selectedShippingMethod && selectedShippingAddress)
                        createOrUpdateOrder(selectedShippingAddress.AddressId, selectedShippingMethod.ShippingMethodId)
                }
            });
        else {
            if (selectedShippingMethod && selectedShippingAddress)
                createOrUpdateOrder(selectedShippingAddress.AddressId, selectedShippingMethod.ShippingMethodId)
        }

    };

    const onClickBack = () => {
        mainButton.offClick(onClickMainButtonHandler);

        backButton.hide();

        onEnableMainButton();

        setPage(1);
        feedback.selectionChanged();
        backButton.offClick(onClickBack);
    }
    // #endregion

    // #region Effects
    useEffect(() => {
        listShippingAddressesAsync();
        customerInfoAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // #endregion

    return (
        <div className={cn(s.cart)}>
            <div
                className={cn(s.cartContainer, {
                    [s.displayNone]: isShippingAddressesPage || isShippingMethodsPage || isCustomerInfoPage
                })}
            >
                <Header
                    titleName="ВАШ ЗАКАЗ"
                    textButton="Изменить"
                    typeButton={ETypeButton.edit}
                    onClickButton={onClickBack}
                />
                <div className={cn(s.cartProducts)}>
                    {cart.map((product: any, key: number) => product.count > 0 && <ItemCart key={key} product={product} />)}
                </div>
                <div className={cn(s.cartFix)}>
                    <Input
                        id="cart-shipping-address-input"
                        readOnly
                        placeholder="Добавить адрес доставки"
                        onClick={() => setIsShippingAddressesPage(true)}
                        value={viewTextShippingAddress}
                    />
                    {selectedShippingAddress &&
                        <Input
                            id="cart-shipping-method-input"
                            readOnly
                            placeholder="Выбрать метод доставки"
                            onClick={() => setIsShippingMethodsPage(true)}
                            value={viewTextShippingMethod}
                        />
                    }
                    <Input
                        id="cart-customer-info-input"
                        readOnly
                        placeholder="Контактные данные"
                        onClick={() => setIsCustomerInfoPage(true)}
                        value={viewTextCustomerInfo}
                    />
                </div>
            </div>

            {isShippingAddressesPage &&
                <ShippingAddresses
                    identityToken={identityToken}
                    setStatusAlert={setStatusAlert}
                    onBack={() => setIsShippingAddressesPage(false)}
                    shippingAddresses={shippingAddresses}
                    refreshShippingAddresses={() => listShippingAddressesAsync()}
                />
            }

            {isShippingMethodsPage &&
                <ShippingMethods
                    shippingMethods={shippingMethods}
                    onBack={() => setIsShippingMethodsPage(false)}
                    setShippingMethod={setSelectedShippingMethod}
                />
            }

            {isCustomerInfoPage &&
                <CustomerInfo
                    customerInfo={customerInfo}
                    onBack={() => setIsCustomerInfoPage(false)}
                    identityToken={identityToken}
                    setStatusAlert={setStatusAlert}
                    refreshCustomerInfo={customerInfoAsync}
                />
            }

            {isShowWebAppButton &&
                <TelegramMainButton
                    onClick={() => onClickMainButtonHandler()}
                    text={mainButtonText}
                    isDisabled={!selectedShippingMethod || !selectedShippingAddress || viewTextCustomerInfo === "   "}
                />
            }
            {isShowWebAppButton &&
                <TelegramBackButton onClick={() => onClickBack()} />
            }
        </div>
    )
}

export default memo(Cart);