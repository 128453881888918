import { FC } from "react";

import cn from "classnames";
import s from "./header.module.css";
import { ETypeButton } from "../../core/enums";

// TODO: Переименовать название интерфейса
interface IHeaderProps {
    titleName: string;
    textButton?: string;
    onClickButton?: () => void;
    typeButton?: ETypeButton;
    href?: string;
    titleHeight?: boolean;
}

const Header: FC<IHeaderProps> = (
    {
        titleName,
        textButton,
        onClickButton,
        typeButton,
        href,
        titleHeight
    }
) => (
    <div className={cn(s.header)}>
        <span className={cn(s.title, { [s.titleHeight]: titleHeight })}>
            {titleName}
        </span>
        {textButton && !href ?
            <span className={cn(s.button, { [s[typeButton!]]: typeButton })} onClick={onClickButton}>
                {textButton}
            </span>
            :
            <a className={cn(s.button, { [s[typeButton!]]: typeButton })} href={href}>
                {textButton}
            </a>
        }

    </div>
);

export default Header;