import axios from "axios";
import { fillmartApiURL, fillmartAuthBasic } from "../config";

export const API_UpdateCustomerAddress = async (identityToken: string, body: any) => {
    try {
        const response: any = await axios.request({
            url: 'UpdateCustomerAddress',
            baseURL: fillmartApiURL,
            method: 'put',
            data: body,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "identityToken": identityToken,
                "Authorization": fillmartAuthBasic
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}