
import { AddButton } from "../../../components";
import { ICart, IStatusAlert } from "../../../core/types";
import "./addToCartExtended.css"

interface FormLineType {
    productID: number;
    increase: (id: number) => void;
    decrease: (id: number) => void;
    colorTheme: string;
    product: ICart;
    setStatusAlert?: (value: IStatusAlert ) => void;
}

/**
* Компонент добавления товара в корзину, с ценной и подсчётом общей суммы и колличества набранного товара. 
* @component
* @param productID id товара
* @param increase Функция добавления товара в корзину
* @param decrease Функция вычитания товара из корзины
* @param colorTheme Цветовая палитра темы
* @param product Данные о позиции товара
* @param setStatusAlert Вызов алерта
*/
const AddToCartExtended: React.FC<FormLineType> = ({ productID, increase, decrease, colorTheme, product, setStatusAlert }) => {

    const { price, count, quantity } = product;

    return (
        <div className={['addToCartExtended', colorTheme].join(' ')}>
            <div>
                {count !== 0 && <div>
                    <span className="addToCartExtended_amount">{count} шт. <span style={{ color: "#9990FF" }}>x</span></span>
                    <span className="addToCartExtended_price">{price.toLocaleString()} ₽</span>
                    <span className="addToCartExtended_total_price">{(price * count).toLocaleString()} ₽</span>
                </div>}
            </div>

            <AddButton productID={productID} count={count} quantity={quantity}
                decrease={decrease} increase={increase} name={price.toLocaleString() + "  ₽"}
                btn_style={{ "height": 45, "width": 110 }}
                addBtn_style={{ "borderRadius": 12, "fontSize": 15, }}
                btnMinusPlus_style={{ "backgroundColor": "#CBD5E0", "borderRadius": 12 }}
                setStatusAlert={setStatusAlert}
            />
        </div>
    )
}


export default AddToCartExtended;