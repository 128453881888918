import { useEffect, useState } from "react";
import AddToCartExtended from "./AddToCartExtended/AddToCartExtended";
import DetailCard from "./DetailCard/DetailCard";
import HeaderCard from "./HeaderCard/HeaderCard";
import "./card.css"
import { ICardActive } from "../../core/types/ICardActive";
import { ICart, IStatusAlert } from "../../core/types";
import { useTelegram } from "../../core/hooks";
import { TelegramBackButton } from "../../components";

interface FormLineType {
    cart: ICart[];
    productID: number;
    setCardActive: (value: ICardActive) => void;
    increase: (id: number) => void;
    decrease: (id: number) => void;
    colorTheme: string;
    mainButtonText: string;
    totalPrice: number;
    setStatusAlert?: (value: IStatusAlert) => void;
}

/**
* Подробнее о товаре
* @component
* @param cart передача товаров
* @param productID id товара
* @param increase Функция добавления товара в корзину
* @param decrease Функция вычитания товара из корзины
* @param setCardActive Установка активности карточки товара
* @param colorTheme Цветовая палитра темы
* @param mainButtonText Текст ТГ кнопки
* @param totalPrice Общая цена
* @param setStatusAlert Вызов алерта
*/
const Card: React.FC<FormLineType> = (
    {
        cart,
        productID,
        increase, decrease,
        setCardActive,
        colorTheme,
        totalPrice,
        setStatusAlert }
) => {

    const [scroll, setScroll] = useState<number>(0);

    const { mainButton, onExpand } = useTelegram();


    // #region Constants
    const product = cart.filter((item: any) => item.id === productID)[0];

    const { count } = product;

    // #endregion

    // #region Functions
    const handleScroll = (e: any) => {
        setScroll(e.currentTarget.scrollTop);
    };

    const onBack = () => {
        setCardActive({ "selected": false, "productID": 0 });
    }
    // #endregion

    // #region Effects
    useEffect(() => {
        onExpand();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (count > 0) {
            mainButton.show();
        }
        else {
            mainButton.hide();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalPrice])
    // #endregion

    return (
        <div className="card-popup">
            <div className="card-detail" onScroll={(event: any) => handleScroll(event)}>

                <HeaderCard
                    product={product}
                    scroll={scroll}
                />

                <DetailCard
                    product={product}
                    scroll={scroll}
                />

            </div>

            <AddToCartExtended
                productID={productID} product={product} setStatusAlert={setStatusAlert}
                decrease={decrease} increase={increase} colorTheme={colorTheme}
            />
            <TelegramBackButton onClick={() => onBack()} />
        </div>
    )
}


export { Card };