import "./shareButton.css"
import { useSVG } from "../SVG";

interface IShareButtonProps {
    link: string;
    startParam?: string;
    shareText?: string | null;
}

const ShareButton: React.FC<IShareButtonProps> = ({ startParam, link, shareText }) => {
    const { shareIcon } = useSVG();

    const toStartParam = startParam ? `?startapp=${startParam}` : '';
    const toShareText = shareText ? `&text=${shareText}` : '';

    const shareUrl = `https://t.me/share/url?url=${link}${toStartParam}${toShareText}`

    return (
        <a className="shareButton" href={shareUrl}>{shareIcon}</a>
    )
}

export default ShareButton;