import { useEffect, useState, memo } from "react";
import "./promotion.css"
import { IPromotion } from "../../core/types";
import { ShareButton } from "../ShareButton";
import { urlFillmartWebApp } from "../../core/consts";

interface IPromotionProps {
    choosePromotion?: (promotion: IPromotion) => void;
    promotion: IPromotion;
    onLoadImg: () => void;
    viewStand?: boolean;
}

/**
* Компонент акции
* @param promotion название акции
* @param onLoadImg Триггер загруженного изображения
*/
const Promotion: React.FC<IPromotionProps> = ({ choosePromotion, promotion, onLoadImg, viewStand }) => {

    const promotionViewStand: string = viewStand ? "promotion-viewStand" : '';

    const { Name, ImageUrl, FullName, SharingText } = promotion;
    const [isDefailtImage, setIsDefailtImage] = useState<boolean>(false);
    const [imagePromotion, setImagePromotion] = useState<string>();

    const defaultImage = () => {
        const images = ['images/holder.png', 'images/holder2.png', 'images/holder3.png'];
        const randomIndex = Math.floor(Math.random() * images.length);
        const randomImage: string = images[randomIndex];
        return randomImage
    }

    const onErrorImg = () => {
        setImagePromotion(defaultImage);
        setIsDefailtImage(true);
    };

    useEffect(() => {
        if (ImageUrl !== null) {
            setImagePromotion(ImageUrl);
        }
        else {
            setImagePromotion(defaultImage())
            setIsDefailtImage(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={['promotion', promotionViewStand].join(' ')} onClick={() => choosePromotion ? choosePromotion(promotion) : null}>

            <div className="promotion-share">
                {viewStand && <ShareButton link={urlFillmartWebApp} startParam={Name} shareText={SharingText}/>}
            </div>

            <div className="promotion-overlay">
                {isDefailtImage && <div className="promotion-title">
                    <div className="promotion-tags"></div>
                    <span className="promotion-name">{FullName}</span>
                </div>}
            </div>

            <img
                src={imagePromotion}
                onError={() => onErrorImg()}
                alt={Name}
                className='promotion-img'
                onLoad={() => onLoadImg()}
            />

        </div>
    )
}

export default memo(Promotion);