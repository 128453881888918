import axios from "axios";
import { fillmartApiURL, fillmartAuthBasic } from "../config";

export const API_CreateOrUpdateOrder = async (identityToken: string, data: any) => {
    try {
        const response: any = await axios.request({
            url: 'CreateOrUpdateOrder',
            baseURL: fillmartApiURL,
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "identityToken": identityToken,
                "Authorization": fillmartAuthBasic
            },
            data: data
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const API_RejectOrder = async (identityToken: string, orderGuid: any) => {
    try {
        const response: any = await axios.request({
            url: 'RejectOrder',
            baseURL: fillmartApiURL,
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "identityToken": identityToken,
                "orderGuid": orderGuid,
                "Authorization": fillmartAuthBasic
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const API_PostCustomerAddress = async (identityToken: string, body: any) => {
    try {
        const response: any = await axios.request({
            url: 'PostCustomerAddress',
            baseURL: fillmartApiURL,
            method: 'post',
            data: body,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "identityToken": identityToken,
                "Authorization": fillmartAuthBasic
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const API_SetSelectedCustomerAddress = async (identityToken: string, telegramUserId: number, addressId: number) => {
    try {
        const response: any = await axios.request({
            url: 'SetSelectedCustomerAddress',
            baseURL: fillmartApiURL,
            method: 'post',
            params: {
                telegramUserId: telegramUserId,
                addressId: addressId
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "identityToken": identityToken,
                "Authorization": fillmartAuthBasic
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const API_GetShippingMethods = async (identityToken: string, body: any) => {
    try {
        const response: any = await axios.request({
            url: 'GetShippingMethods',
            baseURL: fillmartApiURL,
            method: 'post',
            data: body,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "identityToken": identityToken,
                "Authorization": fillmartAuthBasic
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const API_SetCustomerInfo = async (identityToken: string, body: any) => {
    try {
        const response: any = await axios.request({
            url: 'SetCustomerInfo',
            baseURL: fillmartApiURL,
            method: 'post',
            data: body,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "identityToken": identityToken,
                "Authorization": fillmartAuthBasic
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const API_PaymentYooKassa = async (orderGuid: string, returnUrl: string) => {
    try {
        const response: any = await axios.request({
            url: 'PaymentYooKassa',
            baseURL: fillmartApiURL,
            method: 'post',
            params: {
                orderGuid,
                returnUrl
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": fillmartAuthBasic
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}