import { useState } from "react";
import AddToCartExtendedStand from "./AddToCartExtendedStand/AddToCartExtendedStand";
import "./product.css"
import { ICart, IStatusAlert } from "../../../core/types";
import { OnlyForDoctor } from "../../../components/OnlyForDoctor";

interface FormLineType {
    product: ICart;
    onProduct: (id: number) => void;
    increase: (id: number) => void;
    decrease: (id: number) => void;
    setStatusAlert: (value: IStatusAlert) => void;
    onLoadImg: () => void;
    colorTheme: string;
    noLine?: boolean;
}

/**
* Компонент продукта с изображением и краткой информацией о товаре. Имеет компонент добавления товара. 
* @component
* @param product Данные о позиции товара
* @param onProduct Выбранный товар
* @param increase Функция добавления товара в корзину
* @param decrease Функция вычитания товара из корзины
* @param onLoadImg Триггер загруженного изображения
* @param setStatusAlert Вызов алерта
* @param colorTheme Цветовая палитра темы
*/
const Product: React.FC<FormLineType> = ({ product, onProduct, increase, decrease, setStatusAlert, onLoadImg, colorTheme, noLine }) => {

    const { id, name, img, onlyForDoctor } = product;

    const [showImage, setImage] = useState<string>(img);

    const line = !noLine ? "line-" + colorTheme : null;

    const onErrorImg = () => {
        setImage("images/default-image.png");
        onLoadImg();
    };

    return (
        <div className={['product', line].join(' ')}>

            <img id={name} className="product-img" src={showImage} alt="" onClick={() => onProduct(id)} onLoad={() => onLoadImg()} onError={() => onErrorImg()} />

            <div className="product-info">
                {onlyForDoctor && <OnlyForDoctor/>}
                <div className="product-title" onClick={() => onProduct(id)}>
                    <div lang="ru" className="product-name">
                        {name}
                    </div>
                </div>

                <AddToCartExtendedStand
                    productID={id} product={product} setStatusAlert={setStatusAlert}
                    decrease={decrease} increase={increase}
                />
            </div>
        </div>
    )
}


export default Product;