import { OnlyForDoctor } from "../../../components/OnlyForDoctor";
import "./itemCart.css"

interface FormLineType {
    product: any;
}

/**
* Компонент добавления товара в корзину, с ценной и подсчётом общей суммы и колличества набранного товара. 
* @param product Данные о позиции товара
*/
const ItemCart: React.FC<FormLineType> = ({ product }) => {

    const { img, fullname, count, price, onlyForDoctor } = product;

    return (
        <div className="itemCart">
            <img className="itemCart-img" src={img} alt="" />
            <div className="itemCart-title">
                {onlyForDoctor && <OnlyForDoctor />}
                <div lang="ru" className="itemCart-name">{fullname}</div>
                <span className="itemCart-price">{price.toLocaleString()} ₽</span><span className="itemCart-amount">x {count} шт</span>
            </div>
            <div className="itemCart-total-price">{(price * count).toLocaleString()} ₽</div>
        </div>
    )
}

export default ItemCart;