declare global {
    interface Window {
        Telegram?: any;
    }
}

// /** Подключенный скрипт telegram WebApp*/
let tg = window.Telegram.WebApp;

const useTelegram = () => {

    let colorScheme = tg.colorScheme;

    const feedback = tg.HapticFeedback;

    const backButton = tg.BackButton;

    const mainButton = tg.MainButton;

    const initDataUnsafe = tg.initDataUnsafe;

    const telegramStartParam = initDataUnsafe.start_param;

    const telegramUserId = initDataUnsafe.user.id;

    const telegramUserName = initDataUnsafe.user.username;
    const onEnableClosingConfirmation = () => {
        tg.enableClosingConfirmation();
    };

    const onDisableClosingConfirmation = () => {
        tg.disableClosingConfirmation();
    };

    const onClose = () => {
        tg.close();
    };

    const onExpand = () => {
        tg.expand();
    };

    const onReady = () => {
        tg.ready();
    };

    const onDisableMainButton = () => {
        // mainButton.hide();
        mainButton.disable();
        // mainButton.color = "#E2E8F0";
        mainButton.setParams({ "color": "#E2E8F0" });
        // mainButton.setParams({"color": "#E0FFFF"});
        mainButton.textColor = "#718096";
        // mainButton.show();
    }

    const onEnableMainButton = () => {
        // mainButton.hide();
        mainButton.enable();
        mainButton.setParams({ "color": "#23C16B" });
        // mainButton.color = "#23C16B";
        mainButton.textColor = "#F5F5F5";
        // mainButton.show();
    }

    const onEventThemeChanged = (setColorTheme: (colorTheme: string) => void) => {
        tg.onEvent("themeChanged", function () {
            setColorTheme(tg.colorScheme)
        });
    };

    // TODO
    const onToggleButton = () => {
        if (tg.MainButton.isVisible) {
            tg.MainButton.show();
        }
    }

    const isVersionAtLeast = (version: string) => {
        return tg.isVersionAtLeast(version)
    }

    const openInvoice = (link: string, event: any) => {
        tg.openInvoice(link, event);
    }

    const toogleProgressTelegram = (show: boolean) => {
        show ? tg.MainButton.showProgress() : tg.MainButton.hideProgress();
    }

    return {
        tg,
        onClose,
        onToggleButton,
        colorScheme,
        feedback,
        backButton,
        mainButton,
        initDataUnsafe,
        isVersionAtLeast,
        openInvoice,
        onEnableClosingConfirmation,
        onDisableClosingConfirmation,
        onExpand,
        onReady,
        onEventThemeChanged,
        onDisableMainButton,
        onEnableMainButton,
        telegramUserId,
        telegramUserName,
        telegramStartParam,
        toogleProgressTelegram
    };
}
export { useTelegram };
