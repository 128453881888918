import { useEffect, useState } from "react";
import cn from "classnames";
import s from "./payInfo.module.css";
import { IStatusAlert } from "../../core/types";
import { useTelegram } from "../../core/hooks/useTelegram";
import { TelegramMainButton } from "../../components";
import { Header } from "../../components/Header";
import { EAlertStatus, EAlertText, EPaymentStatus, ETypeButton } from "../../core/enums";
import { API_GetPayInfo } from "../../core/services/get";



interface ICheckoutProps {
    setLoading: (loading: boolean) => void;
    setStatusAlert: (value: IStatusAlert) => void;
}

/**
* Страница сформированного заказа перед оплатой
*/

const PayInfo: React.FC<ICheckoutProps> = ({
    setLoading,
    setStatusAlert
}) => {

    const {
        feedback,
        telegramStartParam,
        onClose,
        toogleProgressTelegram
    } = useTelegram();

    const [paymentStatus, setPaymentStatus] = useState<keyof typeof EPaymentStatus>();

    const mainButtonText: string = "ОБНОВИТЬ";

    // #region Functions
    const payInfoAsync = async () => {
        try {
            setLoading(true);
            const response: any = await API_GetPayInfo(telegramStartParam);

            const { Entity, IsSuccess, Message } = response;

            if (IsSuccess) {
                feedback.notificationOccurred("success");
                setPaymentStatus(Entity);
            } else {
                setStatusAlert({ text: Message ? Message : EAlertText.payInfoError, status: EAlertStatus.error });
            }
            setLoading(false);
        } catch (error) {
            setStatusAlert({ text: EAlertText.payInfoError, status: EAlertStatus.error });

            setLoading(false);
        }
    }

    const onClickMainButtonHandler = () => {
        toogleProgressTelegram(true);
        payInfoAsync();
        toogleProgressTelegram(false);
    };

    const onExit = () => {
        onClose();
    }
    // #endregion

    useEffect(() => {
        payInfoAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={cn(s.payInfo)}>

            <Header
                titleName={`Статус платежа`}
                textButton="Выйти"
                typeButton={ETypeButton.delete}
                onClickButton={onExit}
            />
            <div className={cn(s.status)}>{paymentStatus ? EPaymentStatus[paymentStatus] : "В ожидании"}</div>
            <TelegramMainButton
                onClick={() => onClickMainButtonHandler()}
                text={mainButtonText}
            />
        </div>
    )
}

export default PayInfo;