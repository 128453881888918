import { useState, useEffect } from "react";
import { ICart, ITotal } from "../types";
import { useTelegram } from "./useTelegram";

const useCart = () => {

    const [cart, setCart] = useState<ICart[]>([]);

    const [total, setTotal] = useState<ITotal>(
        {
            price: cart.reduce((prev: any, curr: any) => prev + curr.priceTotal, 0),
            count: cart.reduce((prev: any, curr: any) => prev + curr.count, 0)
        }
    );

    const { feedback } = useTelegram();

    /** Добавление товара */
    const increase = (id: number) => {
        setCart((cart) => {
            return cart.map((product) => {
                if (product.id === id) {
                    feedback.impactOccurred('light');
                    return {
                        ...product,
                        count: product.count + 1,
                        priceTotal: (product.count + 1) * product.price,
                    };
                }
                return product;
            });
        });
    };

    /** Убавление товара */
    const decrease = (id: number) => {
        setCart((cart) => {
            return cart.map((product) => {
                if (product.id === id) {
                    feedback.impactOccurred('light');
                    return {
                        ...product,
                        count: product.count - 1,
                        priceTotal: (product.count - 1) * product.price,
                    };
                }
                return product;
            });
        });
    };

    useEffect(() => {
        setTotal({
            price: cart.reduce((prev: any, curr: any) => prev + curr.priceTotal, 0),
            count: cart.reduce((prev: any, curr: any) => prev + curr.count, 0)
        })
    }, [cart])

    return { cart, setCart, total, increase, decrease };
}
export { useCart };
