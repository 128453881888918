import { useState } from "react";
import "./headerCard.css"
import { ICart } from "../../../core/types";
import { OnlyForDoctor } from "../../../components/OnlyForDoctor";

interface IHeaderCardProps {
    product: ICart;
    scroll: number;
}

/**
* Шапка карточки товара
* @param product Данные о позиции товара
* @param scroll Число скролла
*/
const HeaderCard: React.FC<IHeaderCardProps> = ({ product, scroll }) => {

    // #region Constants
    const { fullname, img, onlyForDoctor } = product;

    const [showImage, setImage] = useState<string>(img);

    const maxScroll: number = 0;

    const cardHeaderCollapse: string = scroll > maxScroll ? "card-header-collapse" : '';
    const cardImgCollapse: string = scroll > maxScroll ? "card-img-collapse" : '';
    const cardNameCollapse: string = scroll > maxScroll ? "card-name-collapse" : '';
    // #endregion

    const onErrorImg = () => {
        setImage("images/default-image.png");
    };

    return (
        <div className={['card-header', cardHeaderCollapse].join(' ')}>
            <img src={showImage} alt="" onError={() => onErrorImg()} className={['card-img', cardImgCollapse].join(' ')} />
            <span className={['card-name', cardNameCollapse].join(' ')}>
                {onlyForDoctor && <OnlyForDoctor/>}
                {fullname}
            </span>
        </div>
    )
}


export default HeaderCard;