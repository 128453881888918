import Lottie from 'react-lottie-player';
import { memo } from "react";
import "./loader.css";
import loadingAnim from './loading.json'

interface ILoaderProps {
    height?: number;
    width?: number;
}

const Loader = ({ height, width }: ILoaderProps) => (
    <div className="player">
        <Lottie
            loop play
            animationData={loadingAnim}
            style={{ height: `${height}rem`, width: `${width}rem` }}
        />
    </div>
);

export default memo(Loader);