import { useEffect, useState, memo } from "react";
import { API_GetPromotionList } from "../../core/services/get";
import { IPromotion, IStatusAlert } from "../../core/types";
import { IPromotionResponse } from "../../core/models";
import { EAlertStatus, EAlertText, ETypeButton } from "../../core/enums";
import { useStatusLoadingImg } from "../../core/hooks";
import { Promotion } from "../../components";

import "./catalog.css"
import { useTelegram } from "../../core/hooks/useTelegram";
import { Header } from "../../components/Header";
import { urlFillmartWebApp } from "../../core/consts";

interface FormLineType {
    identityToken: string;
    setStatusAlert: (value: IStatusAlert) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    formationProducts: (promotionName: string, identityToken: string) => void;
    setSelectPromotion: (promotion: IPromotion) => void
}

/**
* Каталог акций
* @param identityToken токен
* @param setStatusAlert
* @param setPromotionName
*/

const Catalog: React.FC<FormLineType> = ({ identityToken, setStatusAlert, formationProducts, loading, setLoading, setSelectPromotion }) => {

    const displayNoneCatalog: string = loading ? "display-none" : '';

    const [promotions, setPromotions] = useState<IPromotion[]>([]);

    const { onLoadImg } = useStatusLoadingImg(promotions.length, setLoading);

    const { tg } = useTelegram();

    const listStock = async () => {
        await API_GetPromotionList(identityToken).then((response: IPromotionResponse) => {
            setLoading(true);

            const { Entity, IsSuccess, Message } = response;
            try {
                if (IsSuccess) {
                    setPromotions(Entity || []);
                    // TODO: notification завернуть в хук useTelegram
                    tg.HapticFeedback.notificationOccurred("success");
                } else {
                    setStatusAlert({ text: Message ? Message : EAlertText.promotion, status: EAlertStatus.error });
                }

            } catch (error) {
                console.log(error)
                setStatusAlert({ text: EAlertText.promotion, status: EAlertStatus.error });
            }
        }
        )
    }

    const choosePromotion = (promotion: IPromotion) => {
        formationProducts(promotion.Name, identityToken);
        setSelectPromotion(promotion)
    }

    useEffect(() => {
        if (identityToken) {
            listStock()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [identityToken])

    return (
        // <div className="catalog">
        <div className={['catalog', displayNoneCatalog].join(' ')}>
            <Header
                titleName="Каталог акций"
                textButton="Поделиться"
                typeButton={ETypeButton.link}
                href={`https://t.me/share/url?url=${urlFillmartWebApp}`}
                titleHeight
            />
            <div className="promotions">
                {promotions.map((promotion, index) =>
                    <Promotion
                        choosePromotion={choosePromotion}
                        promotion={promotion}
                        key={promotion.Name + index}
                        onLoadImg={onLoadImg}
                    />
                )}
            </div>
        </div>
    )
}

export default memo(Catalog);