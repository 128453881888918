import cn from "classnames";
import s from "./addOrEditShippingAddress.module.css";
import { Input, TelegramBackButton, TelegramMainButton } from "../../../components";
import { IShippingAddress } from "../../../core/types/IShippingAddress";
import useShippingAddressFields from "../../../core/hooks/useShippingAddressFields";
import { IStatusAlert } from "../../../core/types";
import { useTelegram } from "../../../core/hooks";
import { API_UpdateCustomerAddress } from "../../../core/services/put";
import { EAlertStatus, EAlertText, ETypeButton } from "../../../core/enums";
import { isEqual } from "../../../core/utils";
import { API_PostCustomerAddress } from "../../../core/services/post";
import { API_DeleteCustomerAddress } from "../../../core/services/delete";
import { Header } from "../../../components/Header";
import { useState } from "react";

interface IAddOrEditShippingAddressProps {
    onBack: () => void;
    shippingAddress: IShippingAddress | null;
    identityToken: string;
    setStatusAlert: (value: IStatusAlert) => void;
    refreshShippingAddresses: () => void;
}

interface AddressSuggestion {
    value: string;
    data: {
        house: string;
    };
}

/**
* Страница редактирования/добавления адреса доставки
*/
const AddOrEditShippingAddress: React.FC<IAddOrEditShippingAddressProps> = (
    {
        identityToken,
        onBack,
        shippingAddress,
        setStatusAlert,
        refreshShippingAddresses
    }
) => {

    const { fieldsShippingAddress, handleFieldChange } = useShippingAddressFields(shippingAddress ?? {} as IShippingAddress);

    const {
        feedback,
        telegramUserId
    } = useTelegram();

    const [fullName, setFullName] = useState(`${fieldsShippingAddress.FirstName || ''}${fieldsShippingAddress.LastName ? ' ' + fieldsShippingAddress.LastName : ''}`);

    const [fullAddress, setFullAddress] = useState(`${fieldsShippingAddress.City}, ${fieldsShippingAddress.Address1}, ${fieldsShippingAddress.House}`);

    const mainButtonText = shippingAddress ? "Изменить адрес доставки" : "Добавить адрес доставки";

    const customerAddressForUpdate = {
        ...fieldsShippingAddress,
        CountryIsoCode: "RU",
        TelegramUserId: telegramUserId
    };

    const updateShippingAddress = async () => {

        await API_UpdateCustomerAddress(identityToken, JSON.stringify(customerAddressForUpdate)).then((response: any) => {

            const { IsSuccess, Message } = response;
            try {
                if (IsSuccess) {
                    refreshShippingAddresses();
                    // TODO: notification завернуть в хук useTelegram
                    feedback.notificationOccurred("success");
                    onBack();
                } else {
                    setStatusAlert({ text: Message ? Message : EAlertText.updAddress, status: EAlertStatus.error });
                }

            } catch (error) {
                console.log(error)
                setStatusAlert({ text: EAlertText.updAddress, status: EAlertStatus.error });
            }
        })
    }

    const editShippingAddress = async () => {

        await API_PostCustomerAddress(identityToken, JSON.stringify(customerAddressForUpdate)).then((response: any) => {

            const { IsSuccess, Message } = response;
            try {
                if (IsSuccess) {
                    refreshShippingAddresses();
                    // TODO: notification завернуть в хук useTelegram
                    feedback.notificationOccurred("success");
                    onBack();
                } else {
                    setStatusAlert({ text: Message ? Message : EAlertText.updAddress, status: EAlertStatus.error });
                }

            } catch (error) {
                console.log(error)
                setStatusAlert({ text: EAlertText.updAddress, status: EAlertStatus.error });
            }
        })
    }

    const deleteShippingAddress = async () => {

        if (shippingAddress)
            await API_DeleteCustomerAddress(identityToken, telegramUserId, shippingAddress?.AddressId).then((response: any) => {
                // setLoading(true);

                const { IsSuccess, Message } = response;
                try {
                    if (IsSuccess) {
                        refreshShippingAddresses();
                        // TODO: notification завернуть в хук useTelegram
                        feedback.notificationOccurred("success");
                        onBack();
                    } else {
                        setStatusAlert({ text: Message ? Message : EAlertText.delAddress, status: EAlertStatus.error });
                    }

                } catch (error) {
                    console.log(error)
                    setStatusAlert({ text: EAlertText.delAddress, status: EAlertStatus.error });
                }
            })
    }

    const onClickMainButtonHandler = () => {
        if (shippingAddress)
            updateShippingAddress();
        else
            editShippingAddress();
    }

    const handleFullNameChange = (value: string) => {
        setFullName(value);
        const nameParts = value.split(' ');
        handleFieldChange("FirstName", nameParts[0] ?? "");
        handleFieldChange("LastName", nameParts[1] ?? "");
    };

    const [suggestions, setSuggestions] = useState<AddressSuggestion[]>([]);
    const suggestionsWithValue = suggestions.map((suggestion) => suggestion.value);
    const validFullAddress: boolean | undefined = suggestionsWithValue.length > 0 ? fullAddress === suggestionsWithValue[0] : undefined;

    const handleFullAddressChange = (value: string) => {
        setFullAddress(value);
        const nameParts = value.split(', ');
        handleFieldChange("City", nameParts[0] ?? "");
        handleFieldChange("Address1", nameParts[1] ?? "");
        handleFieldChange("House", nameParts[2] ?? "");

        if (value.trim() !== '') {
            fetch(`https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address?query=${value}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Token e55e4f4d0ae5aef7749862f3607f6a5703c59eb6"
                }
            })
                .then(response => response.json())
                .then(data => {
                    setSuggestions(data.suggestions);
                    // setValidFullAddress(data.suggestions[0].value[0] === value)
                    console.log(data)
                })
                .catch(error => console.error(error));
        } else {
            setSuggestions([]);
        }
        // setValidFullAddress(suggestionsWithValue[0] === value)
    };

    const handleAddressSelect = (selectedAddress: string | number | null) => {
        // console.log(selectedAddress)
        if (selectedAddress)
            setFullAddress(selectedAddress.toString());
        setSuggestions([]);
    };

    const areEqual = (shippingAddress ? isEqual(shippingAddress, fieldsShippingAddress) : false) || validFullAddress === false;

    return (
        <div className={cn(s.view)}>
            <Header
                titleName={"Доставка"}
                textButton={shippingAddress ? "Удалить" : undefined}
                typeButton={ETypeButton.delete}
                onClickButton={deleteShippingAddress}
            />
            <div className={cn(s.fields)}>

                <div className={cn(s.fields_group)}>
                    <div className={cn(s.fields_group_title)}>Получатель</div>
                    <Input
                        id="input-fullName"
                        placeholder="Имя Фамилия"
                        value={fullName}
                        onChange={(e) => handleFullNameChange(e.target.value)}
                    />

                    <Input
                        id="input-phoneNumber"
                        placeholder="Номер телефона"
                        value={fieldsShippingAddress.PhoneNumber}
                        onChange={(e) => handleFieldChange("PhoneNumber", e.target.value)}
                    />
                </div>

                <div className={cn(s.fields_group)}>
                    <div className={cn(s.fields_group_title)}>Адрес доставки</div>
                    <Input
                        id="input-city"
                        placeholder="Город, улица, дом"
                        value={fullAddress}
                        onChange={(e) => handleFullAddressChange(e.target.value)}
                        suggestions={suggestionsWithValue}
                        selected={(select) => handleAddressSelect(select)}
                        valid={validFullAddress}
                    // valid={(valid) => console.log(valid)}
                    />
                    <div className={cn(s.fields_group_two_columns)}>
                        <Input
                            id="input-flat"
                            placeholder="Квартира"
                            value={fieldsShippingAddress.Flat}
                            onChange={(e) => handleFieldChange("Flat", e.target.value)}
                        />
                        <Input
                            id="input-intercom"
                            placeholder="Домофон"
                            value={fieldsShippingAddress.Intercom}
                            onChange={(e) => handleFieldChange("Intercom", e.target.value)}
                        />
                    </div>
                    <div className={cn(s.fields_group_two_columns)}>
                        <Input
                            id="input-entranceOfHouse"
                            placeholder="Подъезд"
                            value={fieldsShippingAddress.EntranceOfHouse}
                            onChange={(e) => handleFieldChange("EntranceOfHouse", e.target.value)}
                        />
                        <Input
                            id="input-floor"
                            placeholder="Этаж"
                            value={fieldsShippingAddress.Floor}
                            onChange={(e) => handleFieldChange("Floor", e.target.value)}
                        />
                    </div>

                </div>
            </div>

            < TelegramBackButton
                onClick={() => onBack()}
            />
            <TelegramMainButton
                onClick={() => onClickMainButtonHandler()}
                text={mainButtonText}
                isDisabled={areEqual}
            />
        </div>
    )
}

export default AddOrEditShippingAddress;