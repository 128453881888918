import cn from "classnames";
import s from "./actionView.module.css";
import { useSVG } from "../SVG";

interface IActionViewProps {
    title: string;
    onClickView: () => void;
    onClickAction?: () => void;
    select?: boolean;
}

/**
* Компонент поля адреса
* @param title объект адреса
* @param onClickView клик по полю View
* @param onClickAction клик по полю Action
*/
const ActionView: React.FC<IActionViewProps> = ({ title, onClickView, onClickAction, select }) => {

    const { arrowRightIcon } = useSVG();

    return (
        <div className={cn(s.actionView_container, {[s.actionView_container_noAction]: !onClickAction})}>
            <span className={cn(s.actionView, {[s.actionView_noAction]: !onClickAction, [s.actionView_select]: select})} onClick={onClickView}>
                {title}
            </span>
            {onClickAction && <span className={cn(s.actionView_action)} onClick={onClickAction}>
                {arrowRightIcon}
            </span>}
        </div>

    )
}

export default ActionView;