import cn from "classnames";
import s from "./deliveryMethodView.module.css";

interface IDeliveryMethodViewProps {
    name: string;
    description: string;
    rate: string;
    onClickView: () => void;
    select?: boolean;
}

/**
* Компонент информационного поля метода доставки
* @param name наименование доставки
* @param description описание способа доставки
* @param rate цена доставки
* @param onClickView клик по полю
* @param select состояние означающее выбор поля
*/
const DeliveryMethodView: React.FC<IDeliveryMethodViewProps> = (
    {
        name,
        description,
        rate,
        onClickView,
        select
    }
) => {

    return (
        <div className={cn(s.deliveryMethodView, { [s.select]: select })} onClick={onClickView}>
            <span className={cn(s.name)}>{name}</span>
            <span className={cn(s.rate)}>{rate}</span>
            <span className={cn(s.description)}>{description}</span>
        </div>
    )
}

export default DeliveryMethodView;