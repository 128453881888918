import { useEffect, useState } from "react";
import { IStatusAlert } from "../../core/types";
import "./alertStatus.css"
import { useTelegram } from "../../core/hooks/useTelegram";

interface IAlertStatusProps {
    statusAlert: IStatusAlert;
    setStatusAlert: (value: IStatusAlert) => void;
}

/**
* Компонент для вызова оповещающих сообщений (ошибок, предупреждений, успешных действий)
* @param statusAlert Статус оповещения
* @param setStatusAlert Задать статус оповещения
*/
const AlertStatus: React.FC<IAlertStatusProps> = ({ statusAlert, setStatusAlert }) => {

    const { text, status } = statusAlert

    const [alertShow, setAlertShow] = useState('');

    const {feedback } = useTelegram();

    useEffect(() => {
        if (status !== "") {
            feedback.notificationOccurred(status)
            setAlertShow(`alert-show ${status}`);
        } else setAlertShow('');
        setTimeout(() => setStatusAlert({ text: "", status: "" }), 4000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    return (
        <div className={['alert', alertShow].join(' ')} >
            <div >{text}</div>
        </div>
    )
}

export default AlertStatus;