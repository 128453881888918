import axios from "axios";
import { fillmartApiURL, fillmartAuthBasic } from "../config";

export const API_DeleteCustomerAddress = async (identityToken: string, telegramUserId: number, addressId: number) => {
    try {
        const response: any = await axios.request({
            url: 'DeleteCustomerAddress',
            baseURL: fillmartApiURL,
            method: 'delete',
            params: {
                telegramUserId: telegramUserId,
                addressId: addressId
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "identityToken": identityToken,
                "Authorization": fillmartAuthBasic
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}