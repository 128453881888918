type ObjectType = { [key: string]: any };

const isEqual = (obj1: ObjectType, obj2: ObjectType) => {

    // Проверяем, являются ли obj1 и obj2 объектами
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return false;
    }

    // Получаем ключи свойств объектов
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Проверяем, имеют ли объекты одинаковое количество свойств
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Проверяем каждое свойство объектов на равенство
    for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
        // Рекурсивно вызываем isEqual для вложенных свойств
        // if (!onEqual(obj1[key], obj2[key])) {
        //     console.log("!== key")
        //     return false;
        // }
    }
    
    // Если все проверки успешны, объекты равны
    return true;
}

export { isEqual };