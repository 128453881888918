import { useEffect, RefObject } from "react";

/** Adding functionality to close the dropdown menu once user clicks outside of it */
const useClickOutside = (
  ref: RefObject<HTMLElement>,
  onClick: (event: Event) => void,
  exceptionClick?: RefObject<HTMLElement>
): void => {
  // FIXME: solve this issue
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickOutside = (event: MouseEvent): void => {
    if (
      ref.current &&
      !ref.current.contains(event.target as HTMLElement) &&
      !exceptionClick?.current?.contains(event.target as HTMLElement)
    ) {
      onClick(event);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);
};

export {useClickOutside};
