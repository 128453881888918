
import { ICart } from "../../../core/types";
import "./detailCard.css"

interface IDetailCardProps {
    product: ICart;
    scroll: number;
    // handleScroll: (event: any) => void;
}
/**
* Подробное описание в карточке товара
* @param product Данные о позиции товара
* @param handleScroll - Функция для передачи значения скролла
*/
const DetailCard: React.FC<IDetailCardProps> = ({ 
    product,
    scroll
    // handleScroll 
}) => {
    
    const maxScroll: number = 0;

    const detailCollapse: string = scroll > maxScroll ? "detail-collapse" : '';

    const { description, sketch } = product;
    return (
        <div className={['detail', detailCollapse].join(' ')} 
        // onScroll={(event: any) => handleScroll(event)}
        >
            <div className="detail-sketch" dangerouslySetInnerHTML={{ __html: sketch }} />
            <div className="detail-more" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
    )
}

export default DetailCard;