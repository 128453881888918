import cn from "classnames";
import s from "./select.module.css";
import { useRef } from "react";
import { useClickOutside } from "../../core/hooks";

interface ISelectProps {
    options: string[] | number[];
    onClick: (select: string | number | null) => void;
    onClose: () => void;
    isOpen?: boolean;
    selected?: string | number | null;
}

const Select: React.FC<ISelectProps> = (
    {
        selected,
        onClick,
        isOpen,
        onClose,
        options
    }
) => {

    const selectRef = useRef<HTMLDivElement>(null);

    useClickOutside(
        selectRef,
        () => {
            onClose();
        }
    );

    if (!isOpen) {
        return null;
    }

    return (
        <div
            ref={selectRef}
            className={cn(s.options)}
        >
            {options.map((option: string | number | null) =>
                <div className={cn(s.option)} onClick={() => onClick(option)}>
                    {option}
                </div>
            )}
        </div>
    )
}

export default Select;